import {isDraft} from '@/use/contracts/util'
import {useUserStore} from '@/use/user/store'
import {useEntrepreneursStore} from '@/use/entrepreneurs/store'
import {usePlantingstandardStore} from '@/use/plantingstandard/store'
import {useTimberStore} from '@/use/timber/store'
import {useEnvironmentalAgreementsStore} from '@/use/environmentalAgreements/store'
import {useContractTypeTabs} from '@/use/ui/contractTypeTabs'
import {useQueueSizeStore} from '@/use/ui/queueSize'
import {useLocalstorage} from '../localStorage/store'
import {useAssortmentsStore} from '../assortments/store'
import {useReallocatedareasStore} from '../reallocatedAreas/store'
import {useProductsStore} from '../products/store'
import {useAllmaJobsStore} from '../allmaJobs/store'
import {useContractsStore} from '../contracts/store'
import {useCustomersStore} from '../customers/store'
import {useManagersStore} from '../managers/store'
import {usePriceListsStore} from '~/use/priceList/store'
import {useProjectStore} from '~/use/project/store'
import {useCacheDb} from '~/use/indexedDb/cacheDb'
import {useContractsDb} from '~/use/indexedDb/contractsDb';
import {useCustomersDb} from '~/use/indexedDb/customersDb';
import {useDraftsDb} from '~/use/indexedDb/draftsDb';
import {useManagersDb} from '~/use/indexedDb/managersDb';
import {useQueueDb} from '~/use/indexedDb/queueDb';
import {useProjectOrdersStore} from '~/use/projectOrders/store'
import {useOnline} from '@vueuse/core';
import {useTransportStore} from '~/use/transport/store';
import {useTrackDamageStore} from "~/use/trackDamage/store";
import {useAxiosClient} from "~/use/axios/client";
import {ContractApi} from "~/gen/openapi/sblService";
import {BrowserAuthError} from "@azure/msal-browser";
import {useQuestionStore} from "~/use/question/store";

const { persist } = useLocalstorage()
const { clearCurrentEnvironmentalAgreement } = useEnvironmentalAgreementsStore()
const { clearAssortments, loadAssortments } = useAssortmentsStore()
const { clearEntrepreneurs, loadEntrepreneurs } = useEntrepreneursStore()
const { clearPlantingStandard, loadPlantingstandard } = usePlantingstandardStore()
const { clearReallocatedareas, loadReallocatedareas } = useReallocatedareasStore()
const { clearProducts, loadProducts } = useProductsStore()
const {
  currentUser,
  loadUser,
  clearCurrentUser
} = useUserStore()
const { loadThematicMapTypes } = useAllmaJobsStore()
const { resetActiveTab } = useContractTypeTabs()
const { resetQueueSize } = useQueueSizeStore()
const {
  clearCurrentContract,
  loadContracts,
  loadContractsIncremental
} = useContractsStore()
const {
  clearCurrentCustomer,
  loadCustomers,
} = useCustomersStore()
const { loadManagers } = useManagersStore()
const { clearCurrentTimber } = useTimberStore()
const { loadQuestionTemplates, loadQuestionDefinitions } = useQuestionStore()
const { loadPriceLists, loadPriceTypes, clearPriceLists, clearPriceTypes } = usePriceListsStore()

const { contractsDb } = useContractsDb()
const { customersDb } = useCustomersDb()
const { draftsDb } = useDraftsDb()
const { managersDb } = useManagersDb()
const { queueDb } = useQueueDb()
const { cacheDb } = useCacheDb()

const state = reactive({
  lastUpdated: persist('lastUpdated', null as unknown as Date),
  dataUser: persist('dataUser', ''),
  retry: persist('retry', 0)
})

const lastUpdated = computed((): Date => state.lastUpdated)
const currentUserHasData = computed<boolean>(() => state.dataUser === currentUser.value?.Id)

const setLastUpdated = (): void => {
  state.lastUpdated = new Date()
}

const setRetry = (): void => {
  state.retry = state.retry + 1
}

const resetRetry = (): void => {
  state.retry = 0
}

const cleanUpDrafts = async (): Promise<void> => {
  const drafts = await draftsDb.getAll()
  const { axiosClient } = useAxiosClient()
  const contractApi = new ContractApi(undefined, '', axiosClient.value)

  const results = await Promise.allSettled(drafts
    .filter(draft => isDraft(draft))
    .map(async draft => {
      const synced = (await contractApi.contractContractWithSlbAppIdExist(draft.Id!))?.data
      return {
        Id: draft.Id,
        synced
      }
    })
  )

  // For all fulfilled requests, loop through and if there is a contract with the temp id
  // delete it from the drafts db
  results
    .filter(({ status }) => status === 'fulfilled')
    .forEach((result: PromiseSettledResult<{id: string, synched: boolean}>) => {
      const { id, synced } = (result as PromiseFulfilledResult<any>).value
      if (synced) {
        draftsDb.delete(id)
      }
    })
}

const clearData = async (): Promise<void> => {
  state.lastUpdated = null
  clearCurrentUser()
  clearCurrentContract()
  clearCurrentTimber()
  clearCurrentCustomer()
  clearEntrepreneurs()
  clearAssortments()
  clearProducts()
  clearPlantingStandard()
  clearReallocatedareas()
  clearCurrentEnvironmentalAgreement()
  clearPriceLists()
  clearPriceTypes()
  resetActiveTab()
  resetQueueSize()

  localStorage.clear()
  await cacheDb.clear()
  await contractsDb.clear()
  await draftsDb.clear()
  await customersDb.clear()
  await queueDb.clear()
  await managersDb.clear()
}

const getStorageQuota = async (): Promise<{availableSpace?: number, quota?: number, usage?: number}>  => {
  if (navigator.storage && navigator.storage.estimate) {
    navigator.storage.estimate().then(async (estimate) => {
      if (!(estimate.quota && estimate.usage)) {
        return {
          availableSpace: undefined,
          quota: undefined,
          usage: undefined
        }
      }
      console.log(`Storage quota: ${estimate.quota}, usage:  ${estimate.usage}, free: ${(estimate.quota - estimate.usage)}`);
      return {
        availableSpace: (estimate?.quota) - (estimate?.usage || 0),
        quota: estimate.quota,
        usage: estimate.usage,
      }
    }).catch((error) => {
      console.error('Failed to estimate storage:', error);
    });
  } else {
    console.warn('navigator.storage.estimate is not supported in this browser.');
  }
  return {
    availableSpace: undefined,
    quota: undefined,
    usage: undefined,
  }
}

const updateDataInBackground = async (force?: boolean): Promise<boolean> => {
  const { loadProjectsReady2Close } = useProjectStore()
  const { loadParkedTransportOrders } = useTransportStore()
  const { loadNonInvoicedOrders } = useProjectOrdersStore()
  const { loadOperationsWithTrackDamage } = useTrackDamageStore()
  const online = useOnline()

  if (!online.value) {
    console.log('updateDataBackground: Offline')
    return false
  }
  try {
    await Promise.all([
      loadUser(),
      loadContracts(),
      loadCustomers(),
      loadEntrepreneurs(force),
      loadAssortments(force),
      loadProducts(force),
      loadPlantingstandard(force),
      loadReallocatedareas(force),
      loadManagers(force),
      loadThematicMapTypes(force),
      loadPriceLists(force),
      loadPriceTypes(force),
      loadProjectsReady2Close(),
      loadNonInvoicedOrders(),
      loadOperationsWithTrackDamage(),
      loadParkedTransportOrders(),
      loadQuestionTemplates(),
      loadQuestionDefinitions(),
    ] as Promise<void>[])
    console.log('All data is updated')
    setLastUpdated()
    resetRetry()
    cleanUpDrafts()
    return true
  } catch (error) {
    if ((error as Error).message !== 'ExpiredAuthSessionError: Both token and refresh token have expired. Your request was aborted.') {
      setRetry()
      if (state.retry < 5) {
        setTimeout(() => {
          updateDataInBackground()
        }, 5000)
      } else {
        throw error
      }
    } else {
      throw error
    }
    return false
  }
}

const updateDataInitial = async (): Promise<boolean> => {
  const { loadProjectsReady2Close } = useProjectStore()
  const { loadParkedTransportOrders } = useTransportStore()
  const { loadNonInvoicedOrders } = useProjectOrdersStore()
  const { loadOperationsWithTrackDamage } = useTrackDamageStore()
  const online = useOnline()

  if (!online.value) {
    console.log('updateDataInitial: Offline')
    return false
  }

  console.log('updateDataInitial')

  try {
    await Promise.all([
      loadUser(),
      loadContracts(),
      loadCustomers(),
      loadEntrepreneurs(true),
      loadAssortments(true),
      loadProducts(true),
      loadPlantingstandard(true),
      loadReallocatedareas(true),
      loadManagers(true),
      loadThematicMapTypes(true),
      loadPriceLists(true),
      loadPriceTypes(true),
      loadProjectsReady2Close(),
      loadNonInvoicedOrders(),
      loadOperationsWithTrackDamage(),
      loadParkedTransportOrders(),
      loadQuestionTemplates(),
      loadQuestionDefinitions(),
    ] as Promise<void>[])
    console.log('All data is updated')
    setLastUpdated()
    state.dataUser = currentUser.value?.Id || ''
    return true
  } catch (error) {
    console.error(error)
    throw error
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDataStore = () => ({
  clearData,
  currentUserHasData,
  lastUpdated,
  updateDataInBackground,
  updateDataInitial,
  getStorageQuota
})
